export {};

if (!Array.prototype.at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (index: number) {
    if (index >= 0) {
      return this[index];
    }

    return this[this.length + index];
  };
}
