import {FunctionComponent, useRef, useContext, PropsWithChildren} from 'react';
import CloseButton from './close-button';
import DialogContext from '../dialog-context';
import styles from './styles.module.scss';

interface ElementProps {
  isCloseButtonVisible?: boolean;
  isCentered?: boolean;
  title?: string;
}

const Header: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  title,
  isCloseButtonVisible = true,
  isCentered = false,
}) => {
  const ref = useRef<HTMLElement>(null);
  const {onClose} = useContext(DialogContext);

  return (
    <header className={styles.header} ref={ref}>
      <div className={styles.content}>
        {title && (
          <div
            className={`${styles.title} ${isCentered ? styles.centered : ''}`}
          >
            {title}
          </div>
        )}

        {children}
      </div>

      {isCloseButtonVisible && <CloseButton onClick={onClose} />}
    </header>
  );
};

export default Header;
